import { Component, Input, OnInit } from '@angular/core';
import { WindowService } from 'src/app/features/core/services/window.service';
import { MenuItem } from '../../interfaces/MenuItem';
import { AFFILIATES } from '../../utils/affiliates';

@Component({
  selector: 'app-front-pages-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() public mainMenuItems: MenuItem[] = [];

  @Input() public secondaryMenuItems: MenuItem[] = [];
}
