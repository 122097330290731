import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WindowService } from '../core/services/window.service';
import { ILocale } from './interfaces/ILocale';
import { LanguageFacade } from './language.facade';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit, OnDestroy {
  public locales: ILocale[] = [];

  public currentRoute = '';

  private localeObserver: Subscription | null = null;

  constructor(
    private languageFacade: LanguageFacade,
    private windowService: WindowService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.locales = this.languageFacade.getAvailableLanguages();
    this.initLocale();
    this.initCurrentRoute();
    this.subscribeLocale();
  }

  public ngOnDestroy(): void {
    this.localeObserver?.unsubscribe();
  }

  public onLanguageUpdate($event: Event, locale: string): void {
    $event.preventDefault();

    this.languageFacade.setCurrentLocale(locale);
  }

  private initCurrentRoute(): void {
    this.currentRoute = this.router.url;
  }

  private subscribeLocale(): void {
    this.localeObserver = this.languageFacade.getCurrentLocale$().subscribe((locale) => {
      //region apps redirect
      if (locale === 'ca') {
        if (
          this.windowService.window &&
          this.windowService.window.location.host !== 'lifeafterme.ca'
        ) {
          this.windowService.window.location.href = `${window.location.protocol}//lifeafterme.ca${this.router.url}`;
        }
        return;
      } else if (locale === 'nl') {
        if (
          this.windowService.window &&
          this.windowService.window.location.host !== 'lifeafterme.nl'
        ) {
          this.windowService.window.location.href = `${window.location.protocol}//lifeafterme.nl${this.router.url}`;
        }
        return;
      }
      //global apps redirect
      else if (
        this.windowService.window &&
        !this.windowService.window.location.pathname
          .toLocaleLowerCase()
          .includes(locale.toLowerCase())
      ) {
        this.windowService.window.location.href = `${window.location.protocol}//${window.location.host}/${locale}${this.router.url}`;
      }
    });
  }
  private initLocale(): void {
    const urlLocale = this.windowService?.window?.location.pathname
      .toLocaleLowerCase()
      .split('/')[1];

    const { region } = environment;
    switch (region) {
      case 'ca':
        this.languageFacade.setCurrentLocale('ca');
        break;
      case 'nl':
        this.languageFacade.setCurrentLocale('nl');
        break;
      default:
        this.languageFacade.setCurrentLocale(urlLocale || 'en');
        break;
    }
  }
}
